//assets
require("../../../images/bg-race-page-header.jpg");
require("../../../images/icons-race-details/Campanella.svg");
require("../../../images/icons-race-details/Calendar.svg");
require("../../../images/icons-race-details/Tiplogia.svg");
require("../../../images/icons-race-details/Location.svg");
require("../../../images/icons-race-details/Iscritti.svg");
require("../../../images/icons-race-details/Download.svg");
require("../../../images/icons-race-details/Price.svg");
require("../../../images/icons-race-details/Categoria.svg");
require("../../../images/icons-race-details/Percorso.svg");
require("../../../images/icons-race-details/Phone.svg");

require("gsap/src/uncompressed/utils/Draggable");
require("../../vendor/greensock/ThrowPropsPlugin");

var initShareButtons = require("../blocks/share");

$(document).ready(function() {

	initShareButtons($(".share-race-results"));

	if ($("#display_race_location_map").length > 0) {
		var geocoder = new google.maps.Geocoder();
		var latlng = {
			lat: parseFloat(RACE_LOCATION_LAT),
			lng: parseFloat(RACE_LOCATION_LNG)
		};

		geocoder.geocode({ location: latlng }, function(results, status) {
			if (status === "OK") {
				$(".race-infos__location > small").html(
					results[0].address_components[2].short_name +
						" (" +
						results[0].address_components[4].short_name +
						")"
				);
			} else {
				window.alert("No results found");
			}
		});

		var map_options = {
			center: {
				lat: parseFloat(RACE_LOCATION_LAT),
				lng: parseFloat(RACE_LOCATION_LNG)
			},
			zoom: 17
		};

		map = new google.maps.Map(
			document.getElementById("display_race_location_map"),
			map_options
		);

		var marker = new google.maps.Marker({
			map: map,
			position: new google.maps.LatLng(RACE_LOCATION_LAT, RACE_LOCATION_LNG)
		});

		// set info boxes height
		var max_height = 0;

		$(".box").each(function() {
			max_height =
				$(this).outerHeight() > max_height ? $(this).outerHeight() : max_height;
		});

		$(".race-subscribe-steps-container").height(max_height);
	}

	$(".read-more").on("click tap", function() {
		if ($(".description-box div").hasClass("collapse")) {
			console.log($(".description-box div")[0].scrollHeight);
			$(".description-box div").css(
				"max-height",
				$(".description-box div")[0].scrollHeight
			);
			$(".description-box div").removeClass("collapse");

			$(this)
				.children()
				.text("READ LESS");
		} else {
			$(".description-box div").css("max-height", "50px");
			$(".description-box div").addClass("collapse");

			$(this)
				.children()
				.text("READ MORE");
		}
	});

	// routes
	/*
	* Init Draggable Routes
	*/
	if( $(".tabs-wrapper").length ) {

		var toggleEnableDraggable = function() {
			if (
				$(".tabs-wrapper").width() <= $(".tabs").width() &&
				draggable_routes[0].enabled()
			) {
				draggable_routes[0].disable();
			} else if (!draggable_routes[0].enabled()) {
				draggable_routes[0].enable();
			}
		};

		draggable_routes = Draggable.create(".tabs-wrapper__tabbed-nav", {
			type: "x",
			edgeResistance: 0.9,
			throwProps: true,
			lockAxis: true,
			bounds: ".routes"
		});

		toggleEnableDraggable();

		$(window).resize(function() {
			toggleEnableDraggable();
		});

		$(".tabs-wrapper .tab").on("click tap", function() {
			var $this = $(this);
			var id_race_route_selected = $(this).data("id_race_route");

			$(".tabs-wrapper .tab").each(function() {
				$(this).removeClass("selected");
			});

			$this.addClass("selected");

			$(".races-tickets .ticket").each(function() {
				$(this).hide();
			});

			$(".races-tickets .ticket").each(function() {
				if (id_race_route_selected == "all") {
					$(this).fadeIn();
				}

				if (
					$(this).data("id_race_route") == id_race_route_selected ||
					$(this).data("id_race_route") == "0"
				) {
					$(this).fadeIn();
				}
			});
		});
	}

	// verify subscription
	$('.verify-email-box button').click( function() {

		$.post(
			APP_ROOT + "/api/" + LOCALE + "/v1/subscriptions/verify-subscription",
			{
				subscription_email: $('.verify-email-box input').val(),
				id_race: ID_RACE,
			},
			function( response ) {
				if( response.subscribed && response.confirmed ) {
					$(".error-message").hide(300);
					$(".success-message").show(300);
					$(".not-confirmed-message").hide(300)
				} else if( !response.subscribed && !response.confirmed ) {
					$(".not-confirmed-message").hide(300)
					$(".error-message").show(300);
					$(".success-message").hide(300);
				} else if( response.subscribed && !response.confirmed ) {
					$(".not-confirmed-message").show(300)
					$(".error-message").hide(300);
					$(".success-message").hide(300);
				}
			}
		,"json"
		);

	});

});
