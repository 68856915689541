$(document).ready(function() {
	if (!localStorage.cookieconsent && localStorage.cookieconsent != "false") {
		$(".cookie-container").fadeIn();
		cookie = true;
	} else {
		console.log(typeof localStorage.cookieconsent);
		if (localStorage.cookieconsent == "true") {
			dataLayer.push({ event: "cookieConsentGiven" });
		}
	}

	$(".accept-cookies").on("click tap", function() {
		let check = true;
		$(".check-container").each(function() {
			if (
				$(this).children(".third").length &&
				$(this)
					.children(".third")
					.is(":checked")
			) {
				check = true;
			} else {
				console.log("third-not-serlect");
				check = false;
			}
		});
		if (check) {
			console.log("check");
			$(".cookie-container").fadeOut();
			localStorage.cookieconsent = true;
			dataLayer.push({ event: "cookieConsentGiven" });
		} else {
			$(".cookie-container").fadeOut();
			localStorage.cookieconsent = false;
		}
	});
});
