function initShareButtons($container) {
	$container.find(".share-fb-button").on("click touch", function() {
		FB.ui(
			{
				method: "share",
				href: $(this).data("link")
			},
			function(response) {
				console.log("shared");
			}
		);
	});

	$container.find(".share-twitter-button").on("click touch", function() {
		var width = 575,
			height = 400,
			left = ($(window).width() - width) / 2,
			top = ($(window).height() - height) / 2,
			url = "http://twitter.com/share",
			opts =
				"status=1" +
				",width=" +
				width +
				",height=" +
				height +
				",top=" +
				top +
				",left=" +
				left;

		window.open(url, "twitter", opts);

		return false;
	});
}

module.exports = initShareButtons;
