$(document).ready(function() {
	$("#search-for-input").keypress(function(event) {
		var keycode = event.keyCode ? event.keyCode : event.which;
		if (keycode == "13") {
			submitSearch();
		}
	});
});

$(".search-box")
	.find("button")
	.click(function() {
		submitSearch();
	});

var submitSearch = function() {
	if ($("#search-for-input").val().length > 2) {
		document.location = "?sq=" + $("#search-for-input").val();
	}
};
