/*
 * Terms conditions
 */
$(document).ready( function () {

	// privacy policy
	$('#privacy-policy-btn').on('click tap', function ( e ) {
		$('#privacy-policy-overlay').fadeIn(300).css('display', 'flex');
		e.preventDefault();
	});

	// terms conditions
	$('#terms-conditions-btn').on('click tap', function ( e ) {
		$('#terms-conditions-overlay').fadeIn(300).css('display', 'flex');
		e.preventDefault();
	});

	// accept buttons
	$('#privacy-policy-overlay .overlay-accept-btn').on('click tap', function () {
		closeScrollableOverlay()
		$('#accept_privacy_policy').prop('checked', true)
	});

	$('#terms-conditions-overlay .overlay-accept-btn').on('click tap', function () {
		closeScrollableOverlay()
		$('#accept_terms_conditions').prop('checked', true)
	});

	// close overlay
	$('.scrollable-overlay').on('click tap', function ( e ) {
		if( e.target.className == 'scrollable-overlay' ) {
			closeScrollableOverlay()
		}
	});

	$('.overlay-close-btn').on('click tap', function () {
		closeScrollableOverlay()
	} );

	$(document).keyup( function(e) {
		if( e.keyCode == 27 ) {
			closeScrollableOverlay()
		}
	} );

	function closeScrollableOverlay() {
		$('.scrollable-overlay').fadeOut(300)
	}

} );
