require("../images/logo-typography.svg");
require("../images/icons/admin/icon-edit.svg");
require("../images/logo-typography.png");

require("../images/logo-typography.png");
require("../images/logo-typography-black.svg");

require("../images/icons/mail-notification-map-icon.png");
require("../images/icons/mail-notification-contact-icon.png");
require("../images/icons/idc-logo.svg");
require("../images/icons/expand-icon.svg");
require("../images/icons/minus-icon.svg");

require("../images/icons/Btn-plane.svg");
require("../images/icons/burger-button-white.svg");
require("../images/icons/close-white.svg");

require("../images/icons/jpg.svg");
require("../images/icons/pdf.svg");

require("../images/icons/at-location.svg");
require("../images/icons/credit-card.svg");
require("../images/icons/wire-transfer.svg");

//alert
require("../images/icons/alert-conferma.svg");
require("../images/icons/alert-info.svg");
require("../images/icons/alert-warning.svg");

require("../images/icons/bank.svg");

require("../images/icons/mail.svg");
require("../images/icons/phone.svg");

require("../images/icons/checked-box.svg");
require("../images/icons/unchecked-box.svg");

require("../images/icons/iscritti.svg");
require("../images/icons/upload.svg");
require("../images/icons/download.svg");
require("../images/icons/printer.svg");

require("../images/icons/admin/icon-copy.svg");
require("../images/icons/admin/icon-delete.svg");
require("../images/icons/admin/icon-edit.svg");

require("../images/icons/delete.svg");
require("../images/icons/mail-contacts.svg");
require("../images/icons/home-address.svg");
require("../images/icons/printer.svg");
require("../images/icons/arrow-left-empty.svg");
require("../images/icons/modify-profile.svg");

require("../images/devices.jpg");
require("../images/desktop.jpg");

require("../images/avatar-placeholder.svg");

require("../images/homepage/landscape.jpg");
require("../images/homepage/runner.png");
require("../images/homepage/timer.png");
require("../images/homepage/timer-sport.svg");
require("../images/homepage/type-race.svg");

require("../images/logo-idc-race.png");
