$(document).ready(function() {
	if ($(".race-block-container").length) {
		var containerWidth = $(".race-block-container").width();
		resizeRaceBlocks(containerWidth);
	}
	$(window).resize(function() {
		if ($(".race-block-container").length) {
			var containerWidth = $(".race-block-container").width();
			resizeRaceBlocks(containerWidth);
		}
	});
});

function resizeRaceBlocks(containerWidth) {
	$(".race-block-dash").each(function() {
		var classToAdd = "race-block-dash";
		if ($(this).hasClass("admin")) {
			classToAdd += " admin";
		}
		if ($(this).hasClass("compact")) {
			classToAdd += " compact";
		}
		if (containerWidth > 340) {
			if ($(this).hasClass("compact") && containerWidth > 597) {
				classToAdd += " race-block-dash-400 race-block-dash-768";
			} else {
				classToAdd += " race-block-dash-400";
			}
		}
		if (containerWidth > 768) {
			if (!$(this).hasClass("compact")) {
				classToAdd += " race-block-dash-768";
			}
		}
		if (containerWidth > 992) {
			classToAdd += " race-block-dash-992";
		}
		if (containerWidth > 1200) {
			classToAdd += " race-block-dash-1200";
		}
		if (containerWidth > 1400) {
			classToAdd += " race-block-dash-1400";
		}
		if (containerWidth > 1720) {
			classToAdd += " race-block-dash-1720";
		}
		if (containerWidth > 2000) {
			classToAdd += " race-block-dash-2000";
		}
		$(this).attr("class", classToAdd);
	});
}
